:root {
  font-size: 19px;
}

@media screen and (max-width: 370px) and (max-height: 650px) {
  :root {
    font-size: 15px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #191919;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 0.4rem;
  font-size: 1rem;
  height: 2.3rem;
  font-family: "Segoe UI";
  color: white;
  background-color: black;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: red;
}

/* selected link */
a:active {
  color: white;
}
.marker-btn {
  background: none;
  border: none;
  padding: 0rem;
}

.marker-btn img {
  width: 0.75rem;
  height: 0.75rem;
}

.markerParent {
  pointer-events: none;
}

.hazardMark {
  width: 0.75rem;
  height: 0.75rem;
  pointer-events: auto;
}

.hazardMarkNoPointer {
  width: 0.75rem;
  height: 0.75rem;
  pointer-events: none;
}

.search-btn {
  background: none;
  border: none;
}

.search-btn img {
  width: 1.5rem;
  height: 1.5rem;
}

/*.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact:after {
  display: none !important;
}*/

.mapboxgl-popup-content {
  font-size: small;
  padding: 0.1rem 0.1rem 0.1rem;
}

.mapboxgl-ctrl-geocoder--button {
  top: 0.313rem;
}

.card-title {
  font-size: 0.8rem;
  margin-bottom: 0.15rem;
}

.card-subtitle {
  font-size: 0.7rem;
  margin-bottom: 0.1rem;
  margin-top: 0rem;
}

.card-text {
  font-size: 0.8rem;
}

.card-body {
  padding: 0.5rem;
}

.card {
  width: 100vw;
  border-radius: 0rem;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 0.156rem;
  left: 0.4rem;
  width: 1.4375rem;
  height: 1.4375rem;
}

.mapboxgl-ctrl-geocoder--input {
  height: 1.875rem;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 0rem;
  margin-left: 0rem;
}

.mapboxgl-ctrl-geocoder--collapsed {
  width: 2.2rem !important;
  min-width: 2.2rem !important;
}

.mapboxgl-ctrl-geolocate {
  height: 1.875rem !important;
}

.top-wrapper {
  top: 2.3rem;
  position: fixed;
  z-index: 3;
}

.bottom-wrapper {
  position: relative;
  top: 25.35rem;
  width: 100% !important;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: black;
  height: 2rem;
  width: 100% !important;
}

.legend-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.map-full-size > div:first-child > div:first-child {
  width: 100vw !important;
  height: 21rem !important;
}*/

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.7895rem;
  padding-right: 0.7895rem;
  color: white;
  background-color: black;
  height: 2.3rem;
  width: 100% !important;
}

.bottom-card {
  margin-bottom: 2.3rem;
}

.heart {
  width: 0.8125rem;
  height: 0.8125rem;
  margin-right: 0.1rem;
}

.mtr {
  width: 0.83rem;
  height: 0.7rem;
  margin-right: 0.1rem;
}

.blinking {
  -webkit-animation: 2s blink ease infinite;
  -moz-animation: 2s blink ease infinite;
  -ms-animation: 2s blink ease infinite;
  -o-animation: 2s blink ease infinite;
  animation: 2s blink ease infinite;
}

.moving-object {
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  pointer-events: none;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 0.789rem;
  }
}

@-moz-keyframes MoveUpDown {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes MoveUpDown {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes MoveUpDown {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes MoveUpDown {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.pulse {
  display: block;
  width: 0.263rem;
  height: 0.263rem;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  margin: 0;
  pointer-events: none;
  box-shadow: 0 0 0 darkred;
  animation: pulse 1.3s infinite;
}

.pulse-finite {
  display: block;
  width: 0.053rem;
  height: 0.053rem;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  margin: 0;
  pointer-events: none;
  box-shadow: 0 0 0 darkred;
  animation: pulse 1s infinite;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 1.579rem rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 1.579rem rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 1.579rem rgba(255, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

/*burger*/
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 1.895rem;
  height: 1.57rem;
  left: 0.421rem;
  top: 0.3rem;
  color: white;
}

.lang {
  color: white;
  font-size: 0.7rem;
  height: 1.895rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 1.263rem;
  width: 1.263rem;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100vh;
  z-index: 4;
}

/* General sidebar styles */
.bm-menu {
  background: #1e1f1e;
  padding: 2.5rem 0 0;
  font-size: 1rem;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

/* Individual item */
.bm-item {
  color: white;
  display: inline-block;
  margin-bottom: 2rem;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/*styling of modal */
.modal-header {
  border-bottom: 0;
  padding: 1rem 1rem 0rem 1rem;
}


.modal-content {
  background-color: darkslategray;
  color: white;
}
